<template>
    <b-modal
        v-bind="$attrs"
        title="Перелив на площадку"
        scrollable
        size="lg"
        ok-variant="success"
        ok-title="Сохранить"
        cancel-title="Отмена"
        @ok="ok(internalAreaId)"
        @hidden="hidden"
        :ok-disabled="areaId === internalAreaId"
    >
        <b-row>
            <b-col cols="12">
                <b-form-group description="Выберите площадку">
                    <area-select v-model="internalAreaId" :value="areaId"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="limit">
            <b-col cols="12">
                <b-form-group description="Загруженность в лотках">
                    <h4>
                        <b-badge
                            :variant="workload > limit ? 'danger' : 'success'"
                        >
                            {{ workload }} из {{ limit }}
                        </b-badge>
                    </h4>
                </b-form-group>
            </b-col>
        </b-row>
        <div class="row" v-if="nomenclature && nomenclature.is_circle_container">
            <div class="col-12">
                <div class="alert alert-danger">
                    <h6><b-icon icon="exclamation-triangle-fill"/> Перенос номенклатуры с типом упаковки: Круглый лоток</h6>
                </div>
            </div>
        </div>
        <b-row>
            <b-col cols="12">
                <div v-for="area of areas">
                    <template v-if="area.id === internalAreaId">
                        На выбранной площадке будут выполнены операции:
                    </template>
                    <template v-if="area.is_default">
                        На площадке по умолчанию (<b>{{ area.name }}</b>) будут выполнены операции:
                    </template>
                    <ul>
                        <li v-for="operation of area.operations">{{ operation.name }} ({{
                                operation.nomenclature.name
                            }})
                        </li>
                    </ul>
                </div>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import {AreaSelect} from '@components';
import {ProductionPlanService, AreaService, NomenclatureService} from '@services';

export default {
    name: 'OverflowToAreaModal',
    props: {
        productionPlan: {
            type: Object,
        },
        areaId: {
            type: Number,
        },
    },
    components: {
        AreaSelect,
    },
    data() {
        return {
            internalAreaId: null,
            workload: null,
            limit: null,
            areas: [],
            nomenclature: null,
        };
    },
    watch: {
        internalAreaId(value) {
            if (value && value !== this.areaId) {
                AreaService.getWorkload(value, this.productionPlan.date).then(({limit, workload}) => {
                    this.workload = workload;
                    this.limit = limit;
                });

                ProductionPlanService.getOperationsOfArea(
                    this.productionPlan.id,
                    {
                        filters: {area_id: value},
                        without_loading: true
                    }
                ).then((areas) => {
                    this.areas = areas;
                });

                NomenclatureService.getById(this.productionPlan.nomenclature_id).then((response) => {
                    this.nomenclature = response.data;
                });
            }
        },
    },
    methods: {
        ok(areaId) {
            this.resetData();
            this.$emit(
                'ok',
                {production_plan_id: this.productionPlan.id, area_id: areaId}
            );
        },
        hidden() {
            this.resetData();
        },
        resetData() {
            this.internalAreaId = null;
            this.workload = null;
            this.limit = null;
            this.areas = [];
            this.nomenclature = null;
        }
    },
}
</script>
