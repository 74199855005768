<template>
    <b-container fluid>
        <b-alert :show="!!msg" dismissible variant="warning">
            Status: {{ msg.status }}
            <ul v-if="msg.errors && msg.errors.length">
                <li v-for="error in msg.errors">{{ error }}</li>
            </ul>
            <ul v-if="msg.warnings && msg.warnings.length">
                <li v-for="warning in msg.warnings">{{ warning }}</li>
            </ul>
        </b-alert>

        <b-card>
            <div class="row">
                <b-col md="4">
                    <b-form-group description="Дата отгрузки">
                        <b-form-datepicker
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            :start-weekday="1"
                            locale="ru"
                            placeholder=""
                            v-model="filters.date"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="4" class="text-center">
                    <b-button @click="generateShiftTasks">Сгенерировать сменные задания</b-button>
                </b-col>

                <b-col md="4" class="text-right">
                    <router-link :to="{ name: 'ProductionPlanCreate' }" class="btn btn-success">Создать</router-link>
                    <b-button variant="primary" @click="applyFilters">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                </b-col>
            </div>

            <div class="row">
                <b-col md="12">
                    <div v-if="deletedId" class="alert alert-success">План #{{ deletedId }} удален.</div>
                    <div v-if="error" class="alert alert-danger">{{ error }}</div>
                </b-col>
            </div>
        </b-card>

        <b-card class="mt-2">
            <b-table
                hover
                responsive
                head-variant="dark"

                :items="items"
                :fields="fields"
                no-local-sorting

                @row-dblclicked="toForm"
            >
                <template #cell(shift_tasks)="data">
                    <b-icon-check-square-fill
                        v-if="data.item.shift_tasks"
                        v-b-tooltip.hover
                        title="Есть сменные задания"
                        variant="success"
                        scale="1.3"
                    />
                    <b-icon-exclamation-triangle-fill
                        v-else
                        v-b-tooltip.hover
                        title="Нет сменных заданий"
                        variant="warning"
                        scale="1.3"
                    />
                </template>

                <template #cell(actions)="row">
                    <b-button-group size="sm">
                        <b-button variant="outline-danger" @click="deleteProductionPlan(row.item.id)">
                            <b-icon-x scale="1.5"/>
                        </b-button>

                        <b-button
                            v-if="isAdmin"
                            variant="danger"
                            @click="forceDeleteProductionPlan(row.item.id)"
                        >
                            <b-icon-trash-fill/>
                        </b-button>

                        <b-button
                            v-if="row.item.is_available_to_overflow_of_areas"
                            @click="selected = row.item"
                            v-b-modal.overflow-to-area-modal
                            variant="outline-danger"
                        >
                            <b-icon-arrow-down-up/>
                        </b-button>

                        <b-button variant="warning" @click="toForm(row.item)">
                            <b-icon-pencil-square/>
                        </b-button>
                    </b-button-group>
                </template>
            </b-table>
        </b-card>

        <overflow-to-area-modal
            id="overflow-to-area-modal"
            :production-plan="selected"
            :area-id="selected.area_id"
            @ok="partialUpdate"
        />
    </b-container>
</template>

<script>
import moment                                                 from 'moment';
import {ROLE_ADMIN}                                           from '@utils/Roles';
import lsfMixin                                               from '@utils/localStorageFilterMixin';
import {PRODUCTION_PLANS_ENDPOINT, PRODUCTION_TASKS_ENDPOINT} from '@utils/endpoints';
import OverflowToAreaModal                                    from './components/OverflowToAreaModal';

const FILTERS_PATTERN = {
    date: moment().format('YYYY-MM-DD'),
};

export default {
    name: 'ProductionPlanList',
    components: {
        OverflowToAreaModal,
    },
    mixins: [lsfMixin],
    data() {
        return {
            msg: '',
            error: null,
            deletedId: null,

            items: [],
            fields: [
                {
                    key: 'id',
                    label: '№',
                },
                {
                    key: 'date',
                    label: 'Дата отгрузки',
                    formatter: (value) => {
                        return moment(value).format('DD.MM.YYYY');
                    },
                },
                {
                    key: 'nomenclature',
                    label: 'Номенклатура',
                },
                {
                    key: 'count',
                    label: 'Количество',
                },
                {
                    key: 'measure_unit',
                    label: 'Единица измерения',
                },
                {
                    key: 'shift_tasks',
                    label: 'Сменные задания',
                    tdClass: 'text-center'
                },
                {
                    key: 'markup_preset',
                    label: 'Пресет маркировки',
                },
                {
                    key: 'author',
                    label: 'Автор',
                },
                {
                    key: 'reason',
                    label: 'Причина создания',
                    tdClass: 'text-center'
                },
                {
                    key: 'area.name',
                    label: 'Площадка',
                },
                {
                    key: 'actions',
                    label: 'Действия',
                }
            ],
            selected: {},
        };
    },
    computed: {
        isAdmin() {
            return this.$auth.user() && this.$auth.user().roles.includes(ROLE_ADMIN);
        },
        preparedFilters() {
            let conditions = {};

            if (this.filters.date) {
                conditions['filters[dateFrom]'] = moment(this.filters.date).format('YYYY-MM-DD');
                conditions['filters[dateTo]'] = moment(this.filters.date).add(1, 'day').format('YYYY-MM-DD');
            }

            return conditions;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler(value) {
                if (value.date) {
                    this.watchFilters(value);
                }
            }
        }
    },
    methods: {
        toForm(item) {
            this.$router.push({name: 'ProductionPlanEdit', params: {id: item.id}});
        },

        deleteProductionPlan(id) {
            if (confirm('Удалить план?')) {
                this.$http
                    .delete(PRODUCTION_PLANS_ENDPOINT + '/' + id, {params: {relations: 0}})
                    .then(() => {
                        this.applyFilters();
                        this.deletedId = id;
                    })
                    .catch(() => this.error = 'Ошибка удаления плана');
            }
        },
        forceDeleteProductionPlan(id) {
            if (confirm('Удалить план и ВСЕ зависимости?')) {
                this.$http
                    .delete(PRODUCTION_PLANS_ENDPOINT + '/' + id, {params: {relations: 1}})
                    .then(() => {
                        this.applyFilters();
                        this.deletedId = id;
                    })
                    .catch(() => this.error = 'Ошибка удаления плана и зависисмостей');
            }
        },

        generateShiftTasks() {
            this.msg = '';
            let date = this.filters.date ? moment(this.filters.date).format('YYYY-MM-DD') : null;

            if (date) {
                let params = {dateFrom: date, dateTo: date};

                this.$http
                    .get(PRODUCTION_TASKS_ENDPOINT + '/generate-by-plan', {params})
                    .then(response => {
                        this.msg = response.data;
                        this.applyFilters();
                    })
                    .catch(error => {
                        if (error.response) {
                            this.msg = error.response.data;
                        }
                    });
            }
        },

        preparedItems(productionPlans) {
            let preparedItems = [];

            productionPlans.forEach((productionPlan) => {
                let markupPresetName = null;

                if (productionPlan.sorted_markup_presets.length !== 0) {
                    let markupPresetNames = new Set();
                    for (let markupPreset of productionPlan.sorted_markup_presets) {
                        markupPresetNames.add(markupPreset.pack_label_name);
                    }

                    markupPresetName = [...markupPresetNames].join('; ');
                }

                preparedItems.push({
                    id: productionPlan.id,
                    date: productionPlan.date,
                    nomenclature: productionPlan.nomenclature.name,
                    nomenclature_id: productionPlan.nomenclature.id,
                    count: productionPlan.count,
                    measure_unit: productionPlan.nomenclature.measure_unit.name,
                    shift_tasks: !!productionPlan.production_tasks.length,
                    markup_preset: markupPresetName,
                    author: productionPlan?.author?.username,
                    reason: productionPlan?.reason?.comment,
                    area: productionPlan.area,
                    is_available_to_overflow_of_areas: productionPlan.is_available_to_overflow_of_areas,
                });
            })

            return preparedItems;
        },

        applyFilters() {
            this.items = [];
            this.error = null;
            this.deletedId = null;

            let params = this.preparedFilters;

            this.$http
                .get(PRODUCTION_PLANS_ENDPOINT + '/presorted', {params})
                .then(response => this.items = this.preparedItems(response.data.data))
                .catch(error => console.log(error));
        },
        partialUpdate({production_plan_id, area_id}) {
            this.$http.patch(`${PRODUCTION_PLANS_ENDPOINT}/${production_plan_id}`, {area_id}).then(() => {
                this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                this.applyFilters();
            });
        }
    },
    created() {
        this.initFilters(this, FILTERS_PATTERN);
    }
}
</script>
